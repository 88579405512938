/**
 * Module dependencies.
 */

import { KeyboardEvent, MouseEvent } from 'react';
import { RouterLink } from 'src/components/core/links/router-link';
import { isExternalUrl } from './url';

/**
 * `OnClickType` type.
 */

type OnClickType = undefined | ((event?: MouseEvent<HTMLAnchorElement>) => void);

/**
 * Export `handleKeyboardEventProps` util.
 */

export function handleKeyboardEventProps(onClick: OnClickType) {
  if (!onClick) {
    return {};
  }

  return {
    onKeyDown: ({ code }: KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      if (['Enter', 'Space'].includes(code)) {
        onClick();
      }
    }
  };
}

/**
 * Export `getClickProps` util.
 */

export const getClickProps = (onClick?: OnClickType) =>
  !onClick
    ? {}
    : {
        ...handleKeyboardEventProps(onClick),
        role: 'button',
        tabIndex: 0
      };

/**
 * `getLinkProps` util.
 */

const getLinkProps = ({ href, target }: any) =>
  !isExternalUrl(href)
    ? {}
    : {
        rel: 'noopener',
        target: target ?? '_blank'
      };

/**
 * Export `setLinkProps` util.
 */

export function setLinkProps(props: any) {
  const { as, href, onClick } = props;
  const isExternal = href && isExternalUrl(href);
  const element = as || (href && !isExternal && RouterLink) || (href && isExternal && 'a') || 'span';

  return {
    ...getClickProps(onClick),
    ...getLinkProps(props),
    as: element
  };
}

/**
 * Export `setButtonLinkProps` util.
 */

export function setButtonLinkProps(props: any): Record<string, any> {
  const linkProps = setLinkProps(props);
  const isButton =
    !props.href && (linkProps.as === 'button' || ['button', 'submit'].includes(props.type) || 'onClick' in props);

  return {
    ...linkProps,
    as: isButton ? 'button' : linkProps.as,
    type: props.type || (isButton ? 'button' : null),
    ...(props.href?.startsWith('#') && {
      onClick: (event: MouseEvent) => {
        const id = props.href.slice(1);
        const element = document.getElementById(id);

        if (element?.onclick || element?.tagName === 'A') {
          event.preventDefault();
          element.click();
        }
      }
    })
  };
}
