/**
 * Module dependencies.
 */

import { Settings, SettingsAgnostic } from './types';
import { getSlugLocale } from 'src/core/utils/locale';

/**
 * Export `localizeAgnosticSettings` normalizer.
 */

export const localizeAgnosticSettings = (settings: Settings | SettingsAgnostic, isClient: boolean): Settings | null => {
  if (!settings) {
    return null;
  }

  if (!('languageSettings' in settings)) {
    // Settings are already localized
    return settings;
  }

  const pathname = isClient && window?.location?.pathname;

  let languageCode = process.env.DEFAULT_LANGUAGE;
  let regionCode = process.env.DEFAULT_REGION;

  if (pathname) {
    const locale = getSlugLocale(pathname.split('/').slice(1));

    if (locale) {
      languageCode = locale.languageCode;
      regionCode = locale.regionCode;
    }
  }

  return {
    globalSettings: settings.languageSettings[languageCode].globalSettings,
    regionSettings: settings.languageSettings[languageCode].regionSettings.find(
      regionSettings => regionSettings.region === regionCode
    )!
  };
};
